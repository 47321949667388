@import '../../App.scss';

.recommendationItemContainer{
  padding: 10px 0;
  // height: 220px;
  // width: 250px;
  padding: 15px 15px 30px 15px;
  border:1px solid $dark_grey;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $mid_grey;
  margin: 5px;
  
  .imgContainer{
    position: relative;
    &:after{
      content: "";
      position: absolute;
      height: 55px;
      width: 55px;
      background: url('../../Assets/play_btn.svg') no-repeat center/contain;
      right: 5px;
      bottom: 5px;
      transition: .25s ease-in-out all;
      opacity: 0;
      cursor: pointer;
    }
  }
  
  &:hover{
    background-color: $main_grey;
    .imgContainer{
      &:after{
        opacity: 1;
        bottom: 10px;
      }
    }
  };
  

  .image{
    position: relative;
    cursor: pointer;

    img.album{
      height: 200px;
      width: 200px;
      max-height: 200px;
      max-width: 200px;
      cursor: pointer;
      border-radius: 5px;
    }
  }


  .text{
    width: 100%;
    padding-left: 5px;

    h6{
      color: white;
      margin:0;
      cursor: pointer;
      max-width: 170px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      font-weight: 300;
      cursor: pointer;
      font-size: 12px;
    }
    
    h5{
      color: white;
      font-size: 18px;
      margin: 15px 0 8px 0;
      cursor: pointer;
      max-width: 170px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      cursor: pointer;
      
      &:hover{
        text-decoration: underline;
      }
    }
  }

}