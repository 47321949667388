@import './Styles/variables.scss';
@import './Styles/fonts.scss';

body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.App {
  width: 100vw;
  min-height: 100vh;
  background: $dark_grey;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  section{
    max-width: 1250px;
  }
}
