@import '../../App.scss';

.appMain{
  height: 100%;
  width: 100%;
  max-width: 1250px;
  padding-bottom: 60px;

  .typeHeader{
    width: calc(100% - 10px);
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing:border-box;
    padding:0 35px;
    margin: 20px 5px 5px;
    border-bottom: 1px solid #cacaca48;

    &:first-of-type{
      margin: 5px;
    }

    h4{
      font-size: 18px;
      color:$main_green
    }
    @media (max-width:500px){
      justify-content: center;
      padding: 0;
    }
  }

  .artistsContainer,
  .recommendationsContainer{
    margin:5px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }


  .tracksContainer{
    margin:5px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .row{
      height: 50px;
      width: calc(100% - 15px);
      margin: 0 auto;
      align-items: center;
      display: flex;
      justify-content: flex-start;
      font-weight: 300;
      cursor: pointer;

      &:not(:first-of-type){
        &:hover{
          background-color: $mid_grey;
        }
      }

      
      &:first-of-type{
        border-bottom: 1px solid #cacaca48;
        .column{
          font-size: 15px;
        }
      }

      p.number{
        display: initial;
      }

      img{
        display: none;
      }

      &:hover{
        p.number{
          display: none;
        }
        .column:first-of-type img{
          display: initial;
          transition: 1s ease all;
          opacity: .8;
        }
      }

      &.topBar{
        *{
          font-size: 13px;
          text-transform: uppercase;
          font-weight: 300;
          color: #7e7e7e !important;
        }
      }

      .column{
        color: #ffffff;
        height: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-left: 10px;

        &:first-of-type{
          width: 5%;
          justify-content: center;
          img{
            height: 25px;
            width: 25px;
            object-fit: contain;
            opacity: 0;
          }
          @media (max-width:650px){
            display: none;
          }
        }

        &:nth-of-type(2){
          width: 40%;

        }

        &:nth-of-type(3){
          width: 20%;

          @media (max-width:650px){
            width: 30%;
          }
        }

        &:nth-of-type(4){
          width: 30%;
        }


        p{
          max-width: 98%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: left;


          @media (max-width:650px){
            font-size: 13px;
          }
        }
      }
    }
  }

  .recommendationsHeader{
    width:100%;
    height: 70px;
    box-sizing:border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;


    h4{
      font-size: 18px;
      color:$main_green;
      margin: 0%;
    }

    img{
      height: 45px;
      width: 45px;
      margin-right: 10px;
    }

  }
}