@import '../../App.scss';

.artistItemContainer{
  padding-top: 15px;
  margin: 5px;
  padding: 15px 15px 30px 15px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $mid_grey;
  
  .imgContainer{
    position: relative;
    &:after{
      content: "";
      position: absolute;
      height: 55px;
      width: 55px;
      background: url('../../Assets/play_btn.svg') no-repeat center/contain;
      right: 5px;
      bottom: 5px;
      transition: .25s ease-in-out all;
      opacity: 0;
      cursor: pointer;
    }
  }
  
  &:hover{
    background-color: $main_grey;
    .imgContainer{
      &:after{
        opacity: 1;
        bottom: 10px;
      }
    }
  };

  img{
    height: 200px;
    width: 200px;
    max-height: 200px;
    max-width: 200px;
    border-radius: 50%;
    cursor: pointer;
  }

  h5{
    color: $main_green;
    margin: 15px 0;
    cursor: pointer;
  }
}